/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "~/components/layout"
import Seo from "~/components/seo"

const FilesPage = ({ data: { page } }) => {
  return (
    <Layout>
      <Seo title={page.title} />
      <section className="py-20 bg-primary">
        <div className="container mx-auto px-8">
          <div className="flex -mx-8">
            <div className="w-full px-8">
            </div>
          </div>
        </div>
      </section>
      <section className="py-24">
        <div className="container mx-auto px-8">
          <div className="flex justify-center -mx-8">
            <div className="w-full md:w-3/4 px-8">
              <h1
                className="text-6xl text-primary mb-8">{page.title}</h1>
              <div
                className="my-8">
                <ul>
                {page.filesFields.files.map(({ file: { localFile } }) => (
                  <li
                    className="py-2 mb-2"
                    key={localFile?.id}>
                    <a
                      href={localFile?.publicURL}>
                       {localFile?.name}
                    </a>
                  </li>
                ))} 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query FilesPageQuery($databaseId: Int!){
    page: wpPage(databaseId: { eq: $databaseId}) {
      databaseId
      title
      content
      filesFields {
        files {
          file {
            localFile {
              id
              name
              publicURL
            }
          }
        }
      }
    }
  }
`

export default FilesPage